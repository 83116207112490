<template>
  <TTView>
    <VRow>
      <VCol>TODO</VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'AssessmentsList',
};
</script>
